html,
body,
#__next {
  height: 100%;
}

a {
  text-decoration: none;
  color: #6b6b6b;
}

.active {
  font-weight: bold;
  color: #040d62;
}
